import React from "react"
import styled from "styled-components"
import A24 from "@media/logos/png/a24.png"
import AirCanada from "@media/logos/png/aircanada.png"
import AmazonStudios from "@media/logos/png/amazonstudios.png"
import Amplify from "@media/logos/png/amplify.png"
import Cineplex from "@media/logos/png/cineplex.png"
import Sundance from "@media/logos/png/sundance.png"

const LogoWrapper = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;

  .logo-link {
    max-width: 100%;
    display: inline-block;
    margin: 20px 12px;
    padding-right: 16px;
    padding-left: 16px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    vertical-align: middle;
  }

  img {
    max-height: 40px;
    max-width: 260px;
    vertical-align: middle;
    display: inline-block;
    border: 0;
  }
`

const Logos = () => (
  <section className="pt3 md-pt0 px2 md-px3">
    <div className="mx-auto center py5 border-bottom border-gray-5">
      <h3>Trusted by thousands of clients worldwide, including...</h3>
      <LogoWrapper className="my3">
        {[
          { logo: A24, name: "A24" },
          { logo: AmazonStudios, name: "AmazonStudios" },
          { logo: Amplify, name: "Amplify" },
          { logo: Sundance, name: "Sundance" },
          { logo: AirCanada, name: "AirCanada" },
          { logo: Cineplex, name: "Cineplex" }
        ].map((partner, i) => (
          <div key={i} className="logo-link">
            <img alt={partner.name} key={i} src={partner.logo} />
          </div>
        ))}
      </LogoWrapper>
    </div>
  </section>
)

export default Logos
