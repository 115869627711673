import React from "react"
import Layout from "@app-components/layout/"
import Header from "@app-components/ui/header/side_by_side/"
import HeaderImage from "@page-components/index/media/header/"
import Info from "@page-components/index/info/"
import Logos from "@page-components/index/logos/"

const Home = () => (
  <Layout
    getStartedButtons={["email"]}
    getStartedText="Drop us your e-mail address and we'll get in touch to schedule a call to discuss how CineSend might be a fit for the needs of your organization."
    getStartedHeader="We'd love to chat"
    page="home">
    <Header
      title="Secure Video Solutions"
      text="CineSend is the industry leader in cloud storage, conversion, and delivery for high-security media applications."
      buttons={[
        {
          text: "Talk to us",
          className: "web_white ",
          type: "link",
          link: {
            to: "/demo",
            type: "internal"
          }
        }
      ]}
      image={<HeaderImage />}
    />
    <Info />
    <Logos />
  </Layout>
)

Home.propTypes = {}

export default Home
