import React from "react"
import { ReactComponent as Convert } from "@media/icons/home/convert.svg"
import { ReactComponent as Deliver } from "@media/icons/home/deliver.svg"
import { ReactComponent as Users } from "@media/icons/home/users.svg"
import VisibilitySensor from "react-visibility-sensor"

const Info = () => (
  <section className="pt3 md-pt0 px2 md-px3">
    <div className="mx-auto max-width-5 center py5  border-bottom border-gray-5">
      <h2>The platform built for film and TV professionals</h2>
      <p className="mx-auto max-width-2 mt1 large-p-tag">
        CineSend is the preferred delivery method for thousands of distributors, producers, and filmmakers all over the
        world.
      </p>
      <VisibilitySensor partialVisibility>
        {({ isVisible }) => (
          <div className="flex flex-wrap items-center my3 justify-center">
            {[
              {
                number: ">30PB",
                text: "media files delivered",
                icon: <Deliver className={isVisible ? "svg-active" : ""} />,
                _id: 1
              },
              {
                number: "75,000+",
                text: "media files converted",
                icon: <Convert className={isVisible ? "svg-active" : ""} />,
                _id: 2
              },
              {
                number: "10,000+",
                text: "users worldwide",
                icon: <Users className={isVisible ? "svg-active" : ""} />,
                _id: 3
              }
            ].map(item => (
              <div className="block items-center justify-center col-12 sm-col-6 md-col-4 mb3 md-mb0" key={item._id}>
                {item.icon}
                <p className="large-p-tag mb0">
                  <strong className="red">{item.number}</strong>
                  &nbsp;
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        )}
      </VisibilitySensor>
    </div>
  </section>
)

export default Info
