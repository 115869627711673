import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Link from "gatsby-link"
import Dropdown from "./dropdown"

const Navigation = ({ navigation, extraHeight }) => (
  <div
    css={css`
      top: ${extraHeight ? "8em" : "5em"};
    `}
    className="xs-hide sm-block absolute right-0 left-0 px2 md-px3">
    <div
      css={css`
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
      `}
      className="flex items-center py2 max-width-5 mx-auto">
      <div className="flex-auto flex items-center">
        <img
          css={css`
            width: 32px;
            height: 32px;
          `}
          alt={navigation.title}
          src={navigation.icon}
        />
        <h3
          css={css`
            font-size: 1.9em;
          `}
          className="ml1">
          <Link to={navigation.base_url}>
            <span className="white">{navigation.title}</span>
          </Link>
        </h3>
      </div>
      {navigation.links &&
        navigation.links.map((link, index) =>
          link.type === "dropdown" ? (
            <Dropdown key={index} link={link} />
          ) : link.type === "external-link" ? (
            <OutboundLink key={index} className={`white bold ml3 ${link.class}`} target="_blank" href={link.to}>
              <span className="white">{link.label}</span>
            </OutboundLink>
          ) : (
            <Link key={index} to={link.to} className={`white bold ml3 ${link.class}`}>
              <span className="white">{link.label}</span>
            </Link>
          )
        )}
    </div>
  </div>
)

Navigation.defaultProps = {
  navigation: {},
  extraHeight: false
}

Navigation.propTypes = {
  navigation: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    links: PropTypes.array,
    base_url: PropTypes.string
  }),
  extraHeight: PropTypes.bool
}

export default Navigation
