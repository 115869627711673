import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { css, keyframes } from "@emotion/core"
import { useOutsideClick, useKeyPress } from "react-use"
import Link from "gatsby-link"
import Icon from "@app-components/utils/icon"

const fade = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Dropdown = ({ link }) => {
  const [isOpen, toggle] = useState(false)
  const dropdownRef = useRef()
  const close = () => {
    if (isOpen) {
      toggle(false)
    }
  }
  const open = e => {
    e.preventDefault()
    toggle(!isOpen)
  }
  useOutsideClick(dropdownRef, () => close())
  if (useKeyPress("Escape") && isOpen) {
    close()
  }
  return (
    <div ref={dropdownRef} className="relative ml3">
      <button
        css={css`
          height: auto;
        `}
        onClick={open}
        className="link">
        <span className="white flex items-center">
          {link.label}
          <span
            className="material-icons ml1"
            css={css`
              transition: transform 0.2s linear;
              transform: rotate(${isOpen ? "180deg" : "0deg"});
            `}>
            keyboard_arrow_down
          </span>
        </span>
      </button>
      {isOpen && (
        <div
          className="pt2 absolute top-100"
          css={css`
            right: 0;
            opacity: 0;
            animation: ${fade} 0.2s linear forwards;
            width: 375px;
            z-index: 100;
          `}>
          <div
            css={css`
              box-shadow: 0 6px 19px 0 rgba(98, 98, 98, 0.59);
            `}
            className="bg-white p1 rounded items-center">
            {link.children.map(l => (
              <Link
                key={l.to}
                to={l.to}
                activeClassName="bg-gray-6"
                css={css`
                  :hover {
                    background: #f9f9f9;
                  }
                `}
                className="flex items-center p2 rounded">
                <Icon icon={l.icon} />
                <div className="pl2 left-align">
                  <strong
                    className="block"
                    css={css`
                      margin-bottom: -0.25em;
                    `}>
                    {l.label}
                  </strong>
                  <p className="mb0">{l.description}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired
  }).isRequired
}

export default Dropdown
